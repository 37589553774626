<!--
 * @Description:周边泊位-详情 aroundParkInfo
 * @Author: zhoucheng
 * @Github:无
 * @Date: 2021-04-01 12:48:17
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="停车场详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <div class="top">
      <div class="top-left">
        <van-image :src="require('@/assets/wxAroundParkSpace/tingchechang.png')"></van-image>
      </div>
      <div class="top-right">
        <div class="parking">{{item.parkName}}</div>
        <div class="row2">
          <span class="row2-1">
            空位：
            <span class="prominent">{{item.emptySpace}}</span>
            个
          </span>
          <span>
            距离：
            <span class="prominent">{{getDistance[0]}}</span>
            {{getDistance[1]}}
          </span>
        </div>
        <div class="row2">
          <span class="row2-1">联系人：{{parkInfo.contact}}</span>
          <span>电话：{{parkInfo.contactPhoneNumber}}</span>
        </div>
        <div class="row4">
          <van-image :src="require('@/assets/wxAroundParkSpace/ditusmall.png')"></van-image>
          <span>{{item.address}}</span>
        </div>
      </div>
    </div>
    <div class="rule-box">
      <!-- 车行道 路内-->
      <van-row class="main"
               v-if="billRuleCode===1">
        <van-row class="top">渝北停车场计费规则</van-row>
        <van-row class="tag">
          <van-tag color="#19A9FC"
                   type="middle"
                   round>按时长计费</van-tag>
        </van-row>
        <van-row class="divider"></van-row>
        <van-row class="middle textOne">计费规则细则</van-row>
        <van-row class="textThree"
                 style="margin-top:12px;margin-bottom: 10px;">
          1.白天(8:00-20:00)</van-row>
        <van-row class="cell-title textFour">
          <van-col span="6">计费金额</van-col>
          <van-col span="6">免费时长</van-col>
          <van-col span="6">计费时长</van-col>
          <van-col span="6">封顶金额</van-col>
        </van-row>
        <van-row class="cell-value textTwo">
          <van-col span="6">1.00元</van-col>
          <van-col span="6">0分钟</van-col>
          <van-col span="6">30分钟</van-col>
          <van-col span="6">不封顶</van-col>
        </van-row>
        <van-row class="textThree"
                 style="margin-top:12px;margin-bottom: 10px;">
          2.夜间(20:00-8:00)</van-row>
        <van-row class="cell-title textFour">
          <van-col span="6">计费金额</van-col>
          <van-col span="6">免费时长</van-col>
          <van-col span="6">计费时长</van-col>
          <van-col span="6">封顶金额</van-col>
        </van-row>
        <van-row class="cell-value textTwo">
          <van-col span="6">0元</van-col>
          <van-col span="6">0分钟</van-col>
          <van-col span="6">30分钟</van-col>
          <van-col span="6">不封顶</van-col>
        </van-row>
        <van-row class="middle textOne">计费规则描述</van-row>
        <van-row class="down textThree">
          一、重要商圈内路段占道停车收费标准及时段 <br>
          1.白天（8:00-22:00）时段每车每半小时2.5元；<br>
          2.夜间（22:00-次日8:00）时段每车每半小时2元，且每车每次最高不超过10元。<br>
          二、重要商圈外路段占道停车收费标准及时段<br>
          1.白天（8:00-20:00）时段每车每半小时1元；<br>
          2.夜间（20:00-次日8:00）时段不收费。<br>
          实施时间：<br>
          新标准自2014年9月1日起实施，之前占道停车收费标准同时作废。
        </van-row>
      </van-row>
      <!-- 人行道  路外-->
      <van-row class="main"
               v-if="billRuleCode===2">
        <van-row class="top">渝北封闭停车场收费规则</van-row>
        <div class="tag">
          <van-tag color="#19A9FC"
                   type="middle"
                   round>按时长计费</van-tag>
        </div>
        <br>
        <div>收费标准</div>
        <van-row class="cell-title textFour">
          <van-col span="6">15分钟内</van-col>
          <van-col span="6">每小时·泊位</van-col>
          <van-col span="6">12小时内</van-col>
          <van-col span="6">24小时内</van-col>
        </van-row>
        <van-row class="cell-value textTwo">
          <van-col span="6">免费</van-col>
          <van-col span="6">2元</van-col>
          <van-col span="6">封顶8元/次</van-col>
          <van-col span="6">封顶15元/次</van-col>
        </van-row>
        <van-row class="cell-title textFour">
          <van-col span="6">收费时段</van-col>
          <van-col span="6">停放时段</van-col>
          <van-col span="6">收费依据</van-col>
        </van-row>
        <van-row class="cell-value textTwo">
          <van-col span="6">全天</van-col>
          <van-col span="6">0:00-24:00</van-col>
          <van-col span="8">渝价[2014]175号</van-col>
        </van-row>
        <br>
        <van-row class="textTwo"
                 style="text-align: center;margin-bottom:20px">
          减免范围按减免政策规定执行。
        </van-row>
      </van-row>
    </div>
    <div class="navBut"
         @click="handleClickNavigate">
      <van-image :src="require('@/assets/wxAroundParkSpace/daohangbai.png')"></van-image>
      <span>导航</span>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import wx from 'weixin-jsapi'
import $wxConfig from '@/utils/wxConfig.js'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      billRuleCode: 0,
      parkTypeCode: 0,
      item: this.$route.query,
      parkInfo: {}
    }
  },
  // 监听属性 类似于data概念
  computed: {
    getDistance () {
      if (Number(this.item.distance) < 1) {
        return [(Number(this.item.distance) * 1000).toFixed(0), '米']
      }
      return [Number(this.item.distance).toFixed(3), '千米']
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    if (this.$route.query.billingRuleDefId === 'V7') {
      this.billRuleCode = 1
    } else if (this.$route.query.billingRuleDefId === '1') {
      this.billRuleCode = 2
    }
    this.getParking()
    this.queryParkType()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 查询停车场信息（类型）
    queryParkType () {
      const info = {
        parkId: this.item.parkId
      }
      this.$queryDict.queryParkInfo(info).then(res => {
        this.parkTypeCode = res.resultEntity.parkTypeCode
      })
    },
    // 获取停车场信息
    getParking () {
      const info = {
        parkId: this.item.parkId
      }
      this.$aroundParkSpace.queryParking(info).then(res => {
        this.parkInfo = res.resultEntity[0]
      })
    },
    // 停车场导航按钮
    handleClickNavigate (item) {
      // 首先配置微信 config, 参数 jsApiList
      $wxConfig(['openLocation']).then(() => {
        console.log('导航开始')
        // 配置完成后调用导航方法
        wx.openLocation({
          latitude: Number(item.latitude), // 纬度，浮点数，范围为90 ~ -90
          longitude: Number(item.longitude), // 经度，浮点数，范围为180 ~ -180。
          address: item.address, // 地址详情说明
          scale: 18, // 地图缩放级别,整形值,范围从1~28。默认为最大
          name: item.address // 位置名
        })
      })
    },
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .top {
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding: 10px;
    box-sizing: border-box;
    background: #ffffff;

    .top-left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
      .van-image {
        width: 40px;
      }
    }
    .top-right {
      flex-grow: 8;
      display: flex;
      flex-direction: column;
      width: 80%;
      .prominent {
        color: #19a9fc;
      }
      .parking {
        width: 100%;
        font-size: 17px;
        font-weight: 600;
        line-height: 30px;
        color: #333333;
      }
      .row2 {
        font-size: 15px;
        line-height: 25px;
        color: #909090;
        .row2-1 {
          margin-right: 10px;
        }
      }
      .row4 {
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 20px;
        color: #909090;
        span {
          width: 100%;
        }
        .van-image {
          width: 10px;
          margin-right: 5px;
        }
      }
      .detail {
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 25px;
        color: #19a9fc;
        .van-image {
          width: 6px;
          margin-left: 10px;
        }
      }
    }
  }
  .rule-box {
    height: calc(100% - 290px);
    margin-top: 15px;
    padding: 5px;
    box-sizing: border-box;
    background: #ffffff;
    overflow: auto;
    .main {
      margin: 12px 3.2% 0px 3.07%;
      height: 100%;
      width: 93.73%;
      .top {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .tag {
        margin: 8px 0 8px 0;
      }
      .divider {
        width: 100%;
        border-top: 1px #cfcfcf dashed;
      }
      .middle {
        margin-top: 15px;
      }
      .cell-title {
        text-align: center;
        border-top: 1px #ebebeb solid;
        height: 30px;
        line-height: 30px;
      }
      .cell-value {
        text-align: center;
        border-top: 1px #ebebeb solid;
        border-bottom: 1px #ebebeb solid;
        height: 38px;
        line-height: 38px;
      }
      .down {
        margin-top: 10px;
        line-height: 23px;
      }
      .textOne {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .textTwo {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .textThree {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      .textFour {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .navBut {
    position: fixed;
    bottom: 20px;
    left: 2.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 40px;
    margin-top: 50px;
    border-radius: 5px;
    background: #19a9fc;
    border-right: 5px;
    font-size: 17px;
    color: #ffffff;
    .van-image {
      width: 17px;
      margin-right: 8px;
    }
    &:active {
      background: #3db8ff;
    }
  }
}
</style>
